import AbstractInputComponent from '../abstract-input.js';
import {RegExp} from '../../../const.js';
// import {checkDomainEmail} from '../../../utils/check-email/check-email.js';


const createEmailInputTemplate = (label, value, isRequired, isDeleteButton) => {
  return (
    `<div class="dropDownList__wrap" data-new="true" data-required="${isRequired}" data-validate="base">
      <label class="dropDownList__label">${label}</label>
      <input class="dropDownList__input" type="email" name="email" value="${value}">
      ${isDeleteButton ? `<button class="master__btn master__btn--delete" type="button" title="Удалить">
          <span class="visuallyhidden">Удалить</span>
        </button>` : ``}
    </div>`
  );
};


export default class EmailInputComponent extends AbstractInputComponent {
  getTemplate() {
    return createEmailInputTemplate(this._label, this._value, this._isRequired, this._isDeleteButton);
  }

  get value() {
    const value = this.inputElement.value;
    this.value = value;
    return value;
  }

  set value(data) {
    this._value = data;
    this.inputElement.value = data;
  }

  _onChangeHandler() {
    this._checkDeleteButton();
    this._showDetailError();
  }

  _onInputHandler() {
    this._showDetailError();
  }

  _validate() {
    if (RegExp.EMAIL.test(this.value)) {
      // if (RegExp.EMAIL.test(this.value) && checkDomainEmail(this.value)) {
      this._removeDetailError();
      this.isValid = true;
      return;
    }

    this.isValid = false;
  }

  _showDetailError() {
    const textError = [];
    // const validateError = `Введите в формате abc@abc.ru или abc@абц.рф`;

    const re = /^[a-z0-9\s._-]+$/i;
    const re2 = /[\s+]/;
    const value = this.value;
    const name = (value.indexOf(`@`) > 0) ? value.slice(0, value.indexOf(`@`)) : value;

    // const isValid = RegExp.EMAIL.test(value);

    if (!re.test(name) && name !== ``) {
      textError.push(`– не латинские буквы`);
    }

    if (re2.test(value)) {
      textError.push(`– пробел`);
    }

    // проверка домена
    // if (!checkDomainEmail(value)) {
    //   textError.push(`– не тот домен`);
    // }

    if (textError.length !== 0) {
      // if (textError.length !== 0 || !isValid) {
      // const textValidate = isValid ? `` : `${validateError}.<br>`;
      const textValidate = ``;
      const text = textError.length !== 0 ? `Указанный адрес содержит неправильные символы: <br>${textError.join(`,<br>`)}` : ``;
      this._renderDetailError(`${textValidate} ${text}`);
      return;
    }

    this._removeDetailError();
  }
}

